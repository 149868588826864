export const CountryFlag = ({
  flagImageUrl,
  dialCode
}: {
  flagImageUrl: string;
  dialCode: string;
}) => (
  <div className="flex space-x-2 justify-between">
    <img className="w-4" src={flagImageUrl} alt={dialCode} />
    <p>{dialCode}</p>
  </div>
);
