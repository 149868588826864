import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PhoneNumberField, TextArea, TextField } from "@/components/Forms";
import { sendMessageToSlackSupportChannel } from "@/services/slack-webhook";
import { ContactFormType } from "../../types";
import { ContactValidationSchema } from "./utils/validation";
import { SuccessToast } from "./SuccessToast";

export const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<ContactFormType>({
    defaultValues: {
      name: "",
      phoneNumber: "",
      email: "",
      message: ""
    },
    resolver: yupResolver(ContactValidationSchema())
  });
  const [dialCode, setDialCode] = useState("+249");
  const [isSending, setIsSending] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const onSend = async (data: ContactFormType) => {
    setIsSending(true);
    const result = await sendMessageToSlackSupportChannel({
      ...data,
      phoneNumber: `${dialCode}${data.phoneNumber}`
    });
    setShowToast(result);
    setIsSending(false);
    reset();
    setTimeout(() => setShowToast(false), 10000);
  };

  return (
    <div className="flex sm:items-center lg:justify-center justify-start xl:space-x-10 lg:space-x-16">
      <div className="lg:flex hidden">
        <img className="xl:w-[300px] w-[200px] " src="/images/robot-chat.svg" alt="robot" />

        <div className="bubble bubble-bottom-left text-sm text-center xl:text-base">
          <p className="first-line  text-[#324B4A]">We love hearing from you. Reach out</p>
          <p className="second-line text-[#324B4A]">and let us know how we can help.</p>
        </div>
      </div>
      <div className="xl:w-[400px] lg:w-[300px] w-full flex flex-col space-y-5">
        {showToast && <SuccessToast onClose={() => setShowToast(false)} />}
        {/* {!showToast && <ErrorToast />} */}

        <TextField label="Full Name" name="name" register={register} error={errors.name?.message} />
        <TextField
          label="Email Address"
          name="email"
          register={register}
          error={errors.email?.message}
        />
        <div className="flex">
          <div className="grow">
            <PhoneNumberField
              label="Phone Number"
              name="phoneNumber"
              register={register}
              error={errors.phoneNumber?.message}
              onDialCodeChanged={(code) => {
                setDialCode(code?.value ?? "");
              }}
            />
          </div>
        </div>

        <TextArea
          label="Message"
          name="message"
          register={register}
          rows={5}
          error={errors.message?.message}
        />
        {isSending ? (
          <div className="dots-loader" style={{ marginTop: 30 }} />
        ) : (
          <button
            type="button"
            className="flex items-center justify-center px-4 py-3 font-semibold rounded-lg space-x-1 bg-primary-700 text-white hover:bg-primary-900"
            onClick={() => handleSubmit(onSend)()}
          >
            <p>SEND</p>
          </button>
        )}
      </div>
    </div>
  );
};
