export const AnimatedChatbot = () => (
  <div className="hidden md:block chatbot-container grow">
    <div id="chatbot">
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </div>
    <div id="chatbot-corner" />
    <div id="antenna">
      <div id="beam" />
      <div id="beam-pulsar" />
    </div>
  </div>
);
