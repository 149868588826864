import { Route, Routes } from "react-router-dom";
import { HomePage } from "@/views/HomePage";
import { PrivacyPolicy } from "@/views/PrivacyPolicy";
import { Layout } from "./layout";

const App = () => (
  <Layout>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  </Layout>
);

export default App;
