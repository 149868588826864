import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";

export const getCountryFlag = (countryCode?: string) => {
  const svg = new Blob([CountryFlagSvg[countryCode as never]], {
    type: "image/svg+xml"
  });
  const url = URL.createObjectURL(svg);

  return url;
};
