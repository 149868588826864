import { Link } from "react-router-dom";
import { ButtonWithIcon } from "@/components/ButtonWithIcon";
import { Header } from "@/components/Header";
import { AnimatedChatbot } from "./components/AnimatedChatbot";
import { Features } from "./components/Features";
import { Platform } from "./components/Platform";
import { ContactForm } from "./components/ContactForm";
import { Clients } from "./components/Clients";

export const HomePage = () => (
  <>
    <img className="md:block hidden absolute top-20" src="/images/wave-bg.png" alt="wave-bg" />
    <div
      id="home"
      className="relative z-10 md:text-center md:text-[#EBEEFF] text-[#324B4A] capitalize w-2/3 xl:text-6xl lg:text-5xl text-4xl font-extrabold pt-20 pb-10 md:mx-auto sm:ml-10 ml-5"
    >
      Transforming the Landscape of our customer experience
    </div>
    <div className="flex md:justify-center animated-list text-center capitalize w-full font-extrabold md:mx-auto text-[#004AAD] mb-5 ml-10">
      <span>
        Omnichannel. <br />
        Automated. <br />
        Optimal.
      </span>
    </div>

    <div className="flex sm:flex-row flex-col justify-center items-center sm:space-x-5 mt-20 sm:space-y-0 space-y-10">
      <img
        className="xl:w-[450px] lg:w-[300px] sm:w-[200px] w-[300px] rounded-md"
        src="/images/dashboard-screenshot.png"
        alt="dashboard"
      />
      <img
        className="xl:w-[200px] sm:w-[100px] w-[200px] rounded-xl"
        src="/images/messenger-screenshot.png"
        alt="what"
      />
      <img
        className="hidden sm:block xl:w-[450px] lg:w-[300px] w-[200px]"
        src="/images/enigma-startup.png"
        alt="enigma"
      />
    </div>

    <div className="flex justify-center my-10 space-x-2">
      <Link to="https://platform.enigma-ai.com/">
        <ButtonWithIcon
          text="Join us"
          icon="/images/icons/white-right-arrow.svg"
          classes="bg-primary-900 text-white"
        />
      </Link>
      <ButtonWithIcon
        text="Try demo"
        icon="/images/icons/play-icon.svg"
        classes="bg-[#FFB856] text-white hidden sm:flex"
      />
    </div>

    <div className="flex items-center justify-between lg:mx-16 sm:mx-10 mx-5 py-10">
      <div>
        <Header text="Personalized Customer Experience" />

        <div className="lg:w-[600px] sm:w-[400px] w-[250px] font-light lg:text-lg text-[#324B4A]">
          The chatbot is powered by advanced natural language processing technology, which allows it
          to understand the nuances of Sudanese dialects. This technology enables the chatbot to
          understand the context of conversations and respond accordingly. The chatbot is also able
          to recognize and respond to slang and colloquialisms, making it easier for users to
          communicate with it.
        </div>
      </div>

      <AnimatedChatbot />
    </div>

    <div className="lg:mx-16 sm:mx-10 mx-5">
      <Header text="Simplifying the global complexity of communication" />
      <div className="lg:w-[600px] sm:w-[400px] w-[250px] lg:text-xl text-lg font-light text-[#324B4A]">
        Take your global messaging to the next level with a platform built for scale, speed, and
        deliverability.
      </div>
    </div>
    <div className="flex flex-col md:flex-row md:justify-center md:space-x-10 my-20">
      <Platform
        name="facebook"
        icon="/images/icons/facebook.svg"
        description="Use Messenger over our cloud contact center and chatbot-building platform to turn your
          Facebook page into a direct customer support channel."
      />
      <div className="border-r-2 my-5 border-[#898EBA]" />
      <Platform
        name="instagram"
        icon="/images/icons/instagram.svg"
        description="Add Instagram direct messaging to your omnichannel communication mix to interact with
        customers on one of the world’s most popular social media platforms."
      />
      <div className="border-r-2 my-5 border-[#898EBA]" />
      <Platform
        name="whatsapp"
        icon="/images/icons/whatsapp.svg"
        description="Offer secure interactions, always-on customer support and create unique journeys across
        industries on the world’s most popular chat app."
      />
    </div>

    <Clients />
    <Features />

    <div id="contact-us" className="sm:mx-16 mx-5 my-20">
      <Header text="Get in Touch" />
      <ContactForm />
    </div>
  </>
);
