import { Header } from "@/components/Header";
import { ClientsLogos } from "./ClientsLogos";

export const Clients = () => (
  <div id="partners" className="lg:mx-16 md:m-10 sm:mx-10 mx-5">
    <Header text="Trusted partner for transformative industries" />
    <div className="marquee my-20">
      <div className="marquee__content">
        <ClientsLogos />
      </div>
      <div className="marquee__content" aria-hidden>
        <ClientsLogos />
      </div>
    </div>
  </div>
);
