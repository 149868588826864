import { HashLink } from "react-router-hash-link";

type _NavbarItemProps = {
  text: string;
  link: string;
};

export const NavbarItem = ({ text, link }: _NavbarItemProps) => (
  <HashLink
    to={link}
    scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "center" })}
    className="font-medium text-[#F8F9FF] lg:px-4 px-2.5 lg:text-base text-sm cursor-pointer rounded-lg mx-2 hover:text-[#FFBD5B]"
  >
    {text}
  </HashLink>
);
