import { Link } from "react-router-dom";
import { ButtonWithIcon } from "@/components/ButtonWithIcon";
import { NavbarItem } from "./NavbarItem";

export const Navbar = () => (
  <div className="flex fixed justify-between items-center py-2 z-50 bg-[#023289] w-full h-20">
    <img className="xl:ml-40 lg:ml-28 lg:w-16 w-16 ml-10" src="/images/white-logo.png" alt="logo" />
    <div className="hidden md:block">
      <NavbarItem link="/#home" text="Home" />
      <NavbarItem link="/#features" text="Features" />
      <NavbarItem link="/#partners" text="Partnerships" />
      <NavbarItem link="/#contact-us" text="Contact us" />
    </div>
    <div className="mr-20">
      <Link to="https://platform.enigma-ai.com/">
        <ButtonWithIcon
          text="Get started"
          icon="/images/icons/white-right-arrow.svg"
          classes="bg-[#FFB856] text-white"
        />
      </Link>
    </div>
  </div>
);
