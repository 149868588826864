import { PropsWithChildren } from "react";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";

export const Layout = ({ children }: PropsWithChildren) => (
  <div className="relative">
    <Navbar />
    <div className="py-20">{children}</div>
    <Footer />
  </div>
);
