import { useState } from "react";
import { FEATURES } from "../../utils/features";
import { Feature } from "./Feature";
import { CollapsableFeature } from "./CollapsableFeature";

export const Features = () => {
  const [currentStep, setCurrentStep] = useState(0);

  function isCurrent(stepIdx: number) {
    return currentStep === stepIdx;
  }

  return (
    <div id="features" className="mx-auto relative h-full">
      <div className="lg:mx-16 hidden lg:block">
        {FEATURES.map((feature, index) => (
          <Feature
            key={feature.title}
            feature={feature}
            isActive={isCurrent(index)}
            onFeatureSelected={() => setCurrentStep(index)}
          />
        ))}
      </div>
      <div className="sm:mx-16 mx-5 lg:hidden">
        {FEATURES.map((feature) => (
          <CollapsableFeature key={feature.title} feature={feature}>
            <div className="animate__animated animate__fast p-5">
              <div className="font-light sm:mx-10 md:text-base text-sm text-[#324B4A]">
                {feature.description}
              </div>
              <img
                className="mx-auto my-5 md:w-2/3 rounded-md shadow-md shadow-[#A9AABC]"
                src={feature.image}
                alt="live-chat"
              />
            </div>
          </CollapsableFeature>
        ))}
      </div>
    </div>
  );
};
