import { IFeature } from "../../types";

type Props = {
  feature: IFeature;
  isActive: boolean;
  onFeatureSelected: Function;
};

export const Feature = ({ feature, isActive, onFeatureSelected }: Props) => (
  <div key={feature.title} className="flex w-full border-l-2 pl-10 border-[#898EBA]">
    <div className="flex items-start justify-between w-[600px] cursor-pointer">
      <div className={`mt-12 absolute ${isActive ? "left-[40px]" : "left-[55px]"} `}>
        {isActive ? (
          <div className="bg-[#044CAC] p-3 rounded-full">
            <img className="w-6" src={`/images/icons/${feature.icon}`} alt={feature.title} />
          </div>
        ) : (
          <div className="bg-white w-5 h-5  border-[#044CAC] border rounded-md" />
        )}
      </div>

      {/*  eslint-disable-next-line */}
      <div
        className={`${
          isActive
            ? "bg-[#044CAC] shadow-[#A9AABC] shadow-md animate__animated animate__fadeIn"
            : ""
        } p-5 rounded-lg`}
        onClick={() => onFeatureSelected()}
      >
        <div
          className={`lg:text-xl text-sm font-semibold capitalize ${
            isActive ? "text-white " : "text-[#A9AABC]"
          }`}
        >
          {feature.title}
        </div>
        <div
          className={`xl:w-[300px] lg:w-[200px] w-[120px] lg:text-xl text-sm font-light  ${
            isActive ? "text-[#CADFFF]" : "text-[#A9AABC]"
          } `}
        >
          {feature.subTitle}
        </div>
      </div>
    </div>

    <div
      className={`w-2/3 absolute right-0 top-0 ${
        isActive ? "block animate__animated animate__fast animate__slideInRight" : "hidden"
      }`}
    >
      <div className={`font-light lg:text-lg text-sm mx-10 ${isActive ? "text-[#324B4A]" : ""}`}>
        {feature.description}
      </div>
      <img
        className="mx-auto my-5 w-2/3 rounded-md shadow-md shadow-[#A9AABC]"
        src={feature.image}
        alt="live-chat"
      />
    </div>
  </div>
);
