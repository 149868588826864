import React, { useId } from "react";
import { Path, UseFormRegister, FieldValues } from "react-hook-form";
import { FormErrorMessage } from "../FormErrorMessage";

type _BaseProps<T extends FieldValues> = {
  rows: number;
  name: Path<T>;
  register: UseFormRegister<T>;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  isRtl?: boolean;
  error?: string;
};

type _InputProps<T extends FieldValues> = _BaseProps<T> &
  Omit<React.ComponentPropsWithoutRef<"textarea">, keyof _BaseProps<T>>;

export const TextArea = <T extends FieldValues>({
  name,
  register,
  rows,
  label,
  placeholder,
  disabled,
  isRtl,
  error,
  ...others
}: _InputProps<T>) => {
  const id = useId();
  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className="block mb-2 text-sm font-medium text-gray-900"
          style={{ direction: isRtl ? "rtl" : "ltr" }}
        >
          {label}
        </label>
      )}
      <textarea
        rows={rows}
        placeholder={placeholder}
        {...register(name)}
        disabled={disabled}
        className={`bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 focus:outline-none focus:border-2 focus:ring-primary-800 focus:border-primary-800 ${
          disabled ? "bg-gray-100 cursor-not-allowed" : ""
        } ${
          error
            ? "bg-red-50  border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500"
            : ""
        }`}
        style={{ direction: isRtl ? "rtl" : "ltr" }}
        {...others}
      />
      {error && <FormErrorMessage error={error} />}
    </div>
  );
};
