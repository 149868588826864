import axios from "axios";

export const sendMessageToSlackSupportChannel = async (values: {
  name: string;
  email: string;
  phoneNumber: string;
  message: string;
}) => {
  const webhookUrl = process.env.REACT_APP_SLACK_WEBHOOK_URL;
  if (!webhookUrl) throw new Error("Missing slack webhook url!");

  const { name, email, phoneNumber, message } = values;
  const response = await axios.post(
    webhookUrl,
    JSON.stringify({
      text: `Name: ${name} \n Email: ${email} \n Mobile Number: ${phoneNumber} \n Message: ${message}`
    }),
    { withCredentials: false }
  );

  if (response.status === 200) return true;
  return false;
};
