import { LegacyRef } from "react";
import { useHover } from "@/hooks/useHover";

type _Props = {
  classes: string;
  text: string;
  icon: string;
};

export const ButtonWithIcon = ({ classes, text, icon }: _Props) => {
  const { hoverRef, isHovered } = useHover();

  return (
    <button
      type="button"
      ref={hoverRef as LegacyRef<HTMLButtonElement>}
      className={`flex items-center justify-center lg:px-6 px-4 py-2 lg:text-base text-sm font-semibold rounded-lg space-x-1 ${classes}`}
    >
      <p>{text}</p>
      <img
        className={`lg:w-6 w-4 ${isHovered ? "animate__animated animate__heartBeat" : ""}`}
        src={icon}
        alt={text}
      />
    </button>
  );
};
