import { FooterItem } from "./FooterItem";

export const Footer = () => (
  <footer className="border-t-2 xl:mx-16 mx-10">
    <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
      <div className="flex xl:w-[500px] sm:w-[400px] sm:justify-start justify-between items-center">
        <img className="w-14 mr-10" src="/images/logo.png" alt="enigma-logo" />
        <div className="flex items-center space-x-5">
          <a href="https://www.linkedin.com/company/72441858">
            <svg
              className="w-5 cursor-pointer hover:fill-[#FFB856]"
              width="95"
              height="95"
              viewBox="0 0 95 95"
              fill="#898EBA"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M94.2295 94.0567H74.704V63.5054C74.704 56.2193 74.5805 46.8473 64.5586 46.8473C54.4008 46.8473 52.8498 54.7876 52.8498 62.9829V94.0567H33.3448V31.2302H52.064V39.8204H52.3314C54.9357 34.8794 61.3043 29.6709 70.8038 29.6709C90.5762 29.6709 94.2295 42.6798 94.2295 59.6011V94.0567ZM11.3303 22.6482C5.05624 22.6482 0 17.5755 0 11.322C0 5.07269 5.05624 0 11.3303 0C17.5755 0 22.6482 5.07269 22.6482 11.322C22.6482 17.5755 17.5755 22.6482 11.3303 22.6482ZM21.0971 94.0567H1.55102V31.2302H21.0971V94.0567Z"
              />
            </svg>
          </a>
          <a href="https://twitter.com/EnigmaAICo">
            <svg
              className="w-6 cursor-pointer hover:fill-[#FFB856]"
              width="95"
              height="77"
              viewBox="0 0 95 77"
              fill="#898EBA"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M94.2295 9.06566C90.7626 10.6047 87.0367 11.6413 83.1261 12.1085C87.1152 9.71742 90.1816 5.92861 91.6264 1.41344C87.8926 3.63176 83.7543 5.23759 79.3491 6.10921C75.8233 2.34788 70.7978 0 65.2382 0C54.5628 0 45.9055 8.65734 45.9055 19.3328C45.9055 20.8483 46.0782 22.3245 46.408 23.738C30.3419 22.9331 16.0936 15.2377 6.56073 3.53753C4.89601 6.39583 3.94193 9.71349 3.94193 13.2589C3.94193 19.9649 7.35383 25.8856 12.5443 29.3525C9.37584 29.2465 6.3919 28.3788 3.78489 26.9339V27.1734C3.78489 36.5414 10.4477 44.3546 19.2935 46.1293C17.672 46.5769 15.9601 46.8085 14.2012 46.8085C12.9526 46.8085 11.7434 46.6868 10.5616 46.463C13.0233 54.1427 20.1612 59.7337 28.6222 59.8907C22.0026 65.0733 13.6672 68.1633 4.61332 68.1633C3.05068 68.1633 1.5116 68.073 0 67.8963C8.55525 73.3773 18.7163 76.5811 29.6352 76.5811C65.195 76.5811 84.6377 47.1226 84.6377 21.5746C84.6377 20.7383 84.6181 19.906 84.5828 19.0775C88.3598 16.3488 91.6382 12.9448 94.2295 9.06566Z"
              />
            </svg>
          </a>
          <a href="https://www.facebook.com/EnigmaAICo">
            <svg
              className="w-6 cursor-pointer hover:fill-[#FFB856]"
              width="94"
              height="94"
              viewBox="0 0 94 94"
              fill="#898EBA"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M94 66.693C94 83.801 83.801 94 66.693 94H61.1C58.515 94 56.4 91.885 56.4 89.3V62.181C56.4 60.912 57.434 59.831 58.703 59.831L66.975 59.69C67.633 59.643 68.197 59.173 68.338 58.515L69.983 49.538C70.124 48.692 69.466 47.893 68.573 47.893L58.562 48.034C57.246 48.034 56.212 47 56.165 45.731L55.977 34.216C55.977 33.464 56.588 32.806 57.387 32.806L68.667 32.618C69.466 32.618 70.077 32.007 70.077 31.208L69.889 19.928C69.889 19.129 69.278 18.518 68.479 18.518L55.789 18.706C47.987 18.847 41.783 25.239 41.924 33.041L42.159 45.966C42.206 47.282 41.172 48.316 39.856 48.363L34.216 48.457C33.417 48.457 32.806 49.068 32.806 49.867L32.947 58.797C32.947 59.596 33.558 60.207 34.357 60.207L39.997 60.113C41.313 60.113 42.347 61.147 42.394 62.416L42.817 89.206C42.864 91.838 40.749 94 38.117 94H27.307C10.199 94 0 83.801 0 66.646V27.307C0 10.199 10.199 0 27.307 0H66.693C83.801 0 94 10.199 94 27.307V66.693Z" />
            </svg>
          </a>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-5 xl:space-x-4 sm:space-x-2 my-5">
        <FooterItem text="info@cloud-connect.sa" icon="/images/icons/message.svg" />
        <FooterItem text="+966 01386 70007" icon="/images/icons/call.svg" />
        <FooterItem text="King Fahd Road, Al Khobar" icon="/images/icons/location.svg" />
      </div>
    </div>

    <div className="mx-2 sm:text-sm text-xs font-light pb-10 text-[#324B4A]">
      © Copyright {new Date().getFullYear()} Enigma Multi Activities Co. ltd All Rights Reserved |{" "}
      <a href="/privacy-policy" className="font-extralight underline hover:text-[#FFB856]">
        Privacy Policy
      </a>
    </div>
  </footer>
);
