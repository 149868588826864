export const ClientsLogos = () => (
  <>
    <img className="md:h-20 h-12" src="/images/icons/clients/tirhal-logo.png" alt="trihal-logo" />
    <img className="md:h-20 h-12" src="/images/icons/clients/249-logo.png" alt="249-logo" />
    <img className="md:h-20 h-12" src="/images/icons/clients/mtn.png" alt="mtn-logo" />
    <img className="md:h-20 h-12" src="/images/icons/clients/meta.png" alt="meta-logo" />
    <img className="md:h-20 h-12" src="/images/icons/clients/aws.png" alt="aws-logo" />
    <img className="md:h-20 h-12" src="/images/icons/clients/360.png" alt="360-logo" />
    <img
      className="md:h-20 h-12"
      src="/images/icons/clients/orange-corners.png"
      alt="orange-logo"
    />
    <img className="md:h-24 h-14" src="/images/icons/clients/nsb.png" alt="nsb-logo" />
    <img className="md:h-20 h-12" src="/images/icons/clients/infobip-logo.png" alt="infobip-logo" />
  </>
);
