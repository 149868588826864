import { PropsWithChildren, useState } from "react";
import styles from "./styles.module.css";
import { IFeature } from "../../types";

type _CollapsableFeatureProps = {
  feature: IFeature;
};

export const CollapsableFeature = ({
  feature,
  children
}: PropsWithChildren<_CollapsableFeatureProps>) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div className={`w-full bg-white rounded-lg shadow ${styles.collapabel_card_contianer}`}>
      {/* eslint-disable-next-line */}
      <div
        className="flex space-x-8 items-center cursor-pointer"
        onClick={() => {
          setCollapsed((prev) => !prev);
        }}
      >
        <img
          className="w-6"
          src="/images/icons/circle-chevron-downword.svg"
          alt="collapsable_icon"
          style={{
            transform: collapsed ? "rotateX(0deg)" : "rotateX(180deg)"
          }}
        />
        <p className="md:text-lg text-sm font-medium text-primary-700">
          {feature.title}
          <br />
          <span className="font-light">{feature.subTitle}</span>
        </p>
      </div>
      {!collapsed && (
        <div className="shadow-none p-0 border-0">
          <div className="p-0">{children}</div>
        </div>
      )}
    </div>
  );
};
