import Select, { SingleValue } from "react-select";
import CountryList from "country-list-with-dial-code-and-flag";
import { CountryFlag } from "@/components/CountryFlag";
import React, { useId } from "react";
import { Path, UseFormRegister, FieldValues } from "react-hook-form";
import { getCountryFlag } from "@/utils/country-flags";
import { FormErrorMessage } from "../FormErrorMessage";

type _BaseProps<T extends FieldValues> = {
  name: Path<T>;
  register: UseFormRegister<T>;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: "text" | "date" | "number" | "password" | "email";
  required?: boolean;
  isRtl?: boolean;
  error?: string;
  onDialCodeChanged?: (
    e: SingleValue<{
      value: string;
      label: JSX.Element;
    }>
  ) => void;
};

type _InputProps<T extends FieldValues> = _BaseProps<T> &
  Omit<React.ComponentPropsWithoutRef<"input">, keyof _BaseProps<T>>;

export const PhoneNumberField = <T extends FieldValues>({
  name,
  onDialCodeChanged = () => {},
  register,
  label,
  placeholder,
  disabled,
  type = "text",
  error,
  ...others
}: _InputProps<T>) => {
  const id = useId();
  const countries = CountryList.getAll();

  return (
    <div>
      {label && (
        <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900">
          {label}
        </label>
      )}
      <div className="flex w-full">
        <Select
          styles={{
            control: (base) => ({
              ...base,
              height: "100%",
              width: "100%",
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
              boxShadow: "none",
              ":focus-within": { border: "2px solid #456BD1", outline: "none" }
              // border: state.isFocused ? "2px solid #456BD1" : "1px solid #cccccc"
            })
          }}
          defaultValue={{
            value: "Sudan",
            label: <CountryFlag flagImageUrl={getCountryFlag("SD")} dialCode="+249" />
          }}
          options={countries.map((country) => ({
            value: country.dial_code,
            label: (
              <CountryFlag
                flagImageUrl={getCountryFlag(country.code)}
                dialCode={country.dial_code}
              />
            )
          }))}
          onChange={(value) => onDialCodeChanged(value)}
        />

        <input
          id={id}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          {...register(name)}
          className={`grow bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-r-lg block p-2.5 focus:outline-none focus:border-2 focus:ring-primary-800 focus:border-primary-800 ${
            disabled ? "bg-gray-100 cursor-not-allowed" : ""
          } ${
            error
              ? "bg-red-50  border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500"
              : ""
          }`}
          {...others}
        />
      </div>
      {error && <FormErrorMessage error={error} />}
    </div>
  );
};
