import * as yup from "yup";

export const ContactValidationSchema = () =>
  yup.object().shape({
    name: yup.string().required("This field is required."),
    email: yup.string().required("This field is required.").email("Please type a valid email"),

    message: yup.string().required("This field is required."),
    phoneNumber: yup.string().required("This field is required.")
  });
