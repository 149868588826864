import React from "react";

type _PlatformProps = {
  name: string;
  icon: string;
  description: string;
};

export const Platform = ({ name, icon, description }: _PlatformProps) => (
  <div className="flex flex-col space-y-10 md:items-center mx-10">
    <img className="sm:w-10 w-8 mx-auto" src={icon} alt={name} />
    <p className="lg:w-64 md:w-40 font-light text-center md:text-sm text-lg text-[#324B4A]">
      {description}
    </p>
  </div>
);
