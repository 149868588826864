type _ErrorMessageProps = {
  error?: string;
};

export const FormErrorMessage = ({ error }: _ErrorMessageProps) => {
  if (!error) {
    return null;
  }
  return (
    <p className="text-red-600 animate__animated animate__headShake">
      <span className="font-medium" style={{ fontSize: 12 }}>
        {error}
      </span>
    </p>
  );
};
