import React from "react";

type _Props = {
  text: string;
  icon: string;
};

export const FooterItem = ({ text, icon }: _Props) => (
  <div className="flex items-center xl:space-x-4 space-x-2">
    <img className="xl:w-6 w-5" src={icon} alt="phone-call" />
    <p className="font-light text-sm">{text}</p>
  </div>
);
