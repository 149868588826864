import { Header } from "@/components/Header";
import { Description, Title, Subtitle, Subheader } from "./components";

export const PrivacyPolicy = () => (
  <div className="sm:mx-16 mx-5">
    <div className="flex items-center justify-between space-x-5 py-10">
      <div className="w-[600px]">
        <Header text="Privacy Policy" />
        <h4 className="text-lg font-light">
          This Privacy Policy describes Our policies and procedures on the collection, use and
          disclosure of Your information when You use the Service and tells You about Your privacy
          rights and how the law protects You. <br /> We use Your Personal data to provide and
          improve the Service. By using the Service, You agree to the collection and use of
          information in accordance with this Privacy Policy.
        </h4>
      </div>
      <img
        className="xl:w-[350px] md:w-[300px] w-[250px] md:mr-20 mr-10 sm:block hidden"
        src="/images/data-security.png"
        alt="data_security"
      />
    </div>
    <div className="sm:w-3/4">
      <div className="text-2xl mb-5 font-bold capitalize text-[#324B4A]">
        Interpretation and Definitions
      </div>
      <Title text="Interpretation" />
      <Description>
        The words of which the initial letter is capitalized have meanings defined under the
        following conditions. The following definitions shall have the same meaning regardless of
        whether they appear in singular or in plural.
      </Description>
      <div className="text-xl font-bold capitalize text-[#324B4A]">Definitions</div>
      <Description>For the purposes of this Privacy Policy:</Description>
      <Subtitle text="Account" />
      <Description>
        Means a unique account created for You to access our Service or parts of our Service.
      </Description>
      <Subtitle text="Affiliate" />
      <Description>
        {`Means an entity that controls, is controlled by or is under common control with a party, where
      "control" means ownership of 50% or more of the shares, equity interest or other
      securities entitled to vote for election of directors or other managing authority.`}
      </Description>
      <Subtitle text="Application" />
      <Description>
        Means any software program provided by the Company downloaded by You on any electronic
        device.
        <span className="font-bold text-[#324B4A]">
          {`Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
    `}
        </span>
        Refers to Enigma AI CO.LTD.
      </Description>
      <Subtitle text="Country" />
      <Description>Refers to Sudan</Description>
      <Subtitle text="Device" />
      <Description>
        Means any device that can access the Service such as a computer, a cellphone or a digital
        tablet.
      </Description>
      <Subtitle text="Personal" />
      <Description>
        Is any information that relates to an identified or identifiable individual.
      </Description>
      <Subtitle text="Service" />
      <Description>Refers to the Application. </Description>
      <Subtitle text="Service Provider" />
      <Description>
        Means any natural or legal person who processes the data on behalf of the Company. It refers
        to third-party companies or individuals employed by the Company to facilitate the Service,
        to provide the Service on behalf of the Company, to perform services related to the Service
        or to assist the Company in analyzing how the Service is used.
      </Description>
      <Subtitle text="Third-party Social Media Service" />
      <Description>
        Refers to any website or any social network website through which a User can log in or
        create an account to use the Service.
      </Description>
      <Subtitle text="Usage Data" />
      <Description>
        Refers to data collected automatically, either generated by the use of the Service or from
        the Service infrastructure itself (for example, the duration of a page visit).
      </Description>
      <Subtitle text="You" />
      <Description>
        Means the individual accessing or using the Service, or the company, or other legal entity
        on behalf of which such individual is accessing or using the Service, as applicable.{" "}
      </Description>
      <Subheader text=" Collecting and Using Your Personal Data" />
      <Subheader text="Types of Data Collected" />
      <Title text="Personal" />
      <Description>
        While using Our Service, We may ask You to provide Us with certain personally identifiable
        information that can be used to contact or identify You. Personally identifiable information
        may include, but is not limited to:
        <ul className="list-disc mx-10">
          <li>First name and last name</li>
          <li>Usage Data</li>
        </ul>
      </Description>
      <Title text="Usage Data" />
      <Description>
        Usage Data is collected automatically when using the Service. Usage Data may include
        information such as Your Device`s Internet Protocol address (e.g. IP address), browser type,
        browser version, the pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other diagnostic data. When You
        access the Service by or through a mobile device, We may collect certain information
        automatically, including, but not limited to, the type of mobile device You use, Your mobile
        device unique ID, the IP address of Your mobile device, Your mobile operating system, the
        type of mobile Internet browser You use, unique device identifiers and other diagnostic
        data. <br /> We may also collect information that Your browser sends whenever You visit our
        Service or when You access the Service by or through a mobile device.
      </Description>

      <Title text="Use of Your Personal Data" />
      <Description>
        <ul className="list-disc sm:mx-10">
          <li>
            <span className="font-bold mr-2">To provide and maintain our Service:</span>
            including to monitor the usage of our Service.
          </li>
          <li>
            <span className="font-bold mr-2">To manage Your Account:</span>
            to manage Your registration as a user of the Service. The Personal Data You provide can
            give You access to different functionalities of the Service that are available to You as
            a registered user.
          </li>
          <li>
            <span className="font-bold mr-2">For the performance of a contract:</span>
            the development, compliance and undertaking of the purchase contract for the products,
            items or services You have purchased or of any other contract with Us through the
            Service.
          </li>
          <li>
            <span className="font-bold mr-2">To contact You:</span>
            To contact You by email, telephone calls, SMS, or other equivalent forms of electronic
            communication, such as a mobile application`s push notifications regarding updates or
            informative communications related to the functionalities, products or contracted
            services, including the security updates, when necessary or reasonable for their
            implementation.
          </li>
          <li>
            <span className="font-bold mr-2">
              To provide You with news, special offers and general information about other goods,
              services and events which we offer that are similar to those that you have already
              purchased or enquired about unless You have opted not to receive such information.
            </span>
          </li>
          <li>
            <span className="font-bold mr-2">To manage Your requests:</span>
            To attend and manage Your requests to Us.
          </li>
          <li>
            <span className="font-bold mr-2">For business transfers:</span>
            We may use Your information to evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding,
            in which Personal Data held by Us about our Service users is among the assets
            transferred.
          </li>
          <li>
            <span className="font-bold mr-2">For other purposes:</span>
            We may use Your information for other purposes, such as data analysis, identifying usage
            trends, determining the effectiveness of our promotional campaigns and to evaluate and
            improve our Service, products, services, marketing and your experience. We may share
            Your personal information in the following situations:
          </li>
          <ul className="mx-10 list-disc">
            <li>
              <span className="font-semibold mr-2">With Service Providers:</span>
              We may share Your personal information with Service Providers to monitor and analyze
              the use of our Service, to contact You.
            </li>
            <li>
              <span className="font-semibold mr-2">For business transfers:</span>
              We may share or transfer Your personal information in connection with, or during
              negotiations of, any merger, sale of Company assets, financing, or acquisition of all
              or a portion of Our business to another company.
            </li>
            <li>
              <span className="font-semibold mr-2">With Affiliates:</span>
              We may share Your information with Our affiliates, in which case we will require those
              affiliates to honor this Privacy Policy. Affiliates include Our parent company and any
              other subsidiaries, joint venture partners or other companies that We control or that
              are under common control with Us.
            </li>
            <li>
              <span className="font-semibold mr-2">With business partners:</span>
              We may share Your information with Our business partners to offer You certain
              products, services or promotions.
            </li>
            <li>
              <span className="font-semibold mr-2">With other users:</span>
              when You share personal information or otherwise interact in the public areas with
              other users, such information may be viewed by all users and may be publicly
              distributed outside. If You interact with other users or register through a
              Third-Party Social Media Service, Your contacts on the Third-Party Social Media
              Service may see Your name, profile, pictures and description of Your activity.
              Similarly, other users will be able to view descriptions of Your activity, communicate
              with You and view Your profile.
            </li>
            <li>
              <span className="font-semibold mr-2">With Your consent:</span>
              We may disclose Your personal information for any other purpose with Your consent.
            </li>
          </ul>
        </ul>
      </Description>
      <Title text="Retention of Your Personal Data" />
      <Description>
        The Company will retain Your Personal Data only for as long as is necessary for the purposes
        set out in this Privacy Policy. We will retain and use Your Personal Data to the extent
        necessary to comply with our legal obligations (for example, if we are required to retain
        your data to comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies. The Company will also retain Usage Data for internal analysis
        purposes. Usage Data is generally retained for a shorter period of time, except when this
        data is used to strengthen the security or to improve the functionality of Our Service, or
        We are legally obligated to retain this data for longer time periods.
      </Description>
      <Title text="Transfer of Your Personal Data" />
      <Description>
        Your information, including Personal Data, is processed at the Company`s operating offices
        and in any other places where the parties involved in the processing are located. It means
        that this information may be transferred to — and maintained on — computers located outside
        of Your state, province, country or other governmental jurisdiction where the data
        protection laws may differ than those from Your jurisdiction. <br />
        Your consent to this Privacy Policy followed by Your submission of such information
        represents Your agreement to that transfer. The Company will take all steps reasonably
        necessary to ensure that Your data is treated securely and in accordance with this Privacy
        Policy and no transfer of Your Personal Data will take place to an organization or a country
        unless there are adequate controls in place including the security of Your data and other
        personal information.
      </Description>
      <Subheader text="Disclosure of Your Personal Data" />
      <Title text="Business Transactions" />
      <Description>
        If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
        transferred. We will provide notice before Your Personal Data is transferred and becomes
        subject to a different Privacy Policy.
      </Description>
      <Title text="Law Enforcement" />
      <Description>
        Under certain circumstances, the Company may be required to disclose Your Personal Data if
        required to do so by law or in response to valid requests by public authorities (e.g. a
        court or a government agency).
      </Description>
      <Title text="Other Legal Requirements" />
      <Description>
        The Company may disclose Your Personal Data in the good faith belief that such action is
        necessary to:
        <ul className="list-disc mx-10">
          <li>Comply with a legal obligation.</li>
          <li>Protect and defend the rights or property of the Company.</li>
          <li>Prevent or investigate possible wrongdoing in connection with the Service.</li>
          <li>Protect the personal safety of Users of the Service or the public.</li>
          <li>Protect against legal liability.</li>
        </ul>
      </Description>
      <Title text="Security of Your Personal Data" />
      <Description>
        The security of Your Personal Data is important to Us, but remember that no method of
        transmission over the Internet, or method of electronic storage is 100% secure. While We
        strive to use commercially acceptable means to protect Your Personal Data, We cannot
        guarantee its absolute security.
      </Description>
      <Subheader text="Children's Privacy" />
      <Description>
        Our Service does not address anyone under the age of 13. We do not knowingly collect
        personally identifiable information from anyone under the age of 13. If You are a parent or
        guardian and You are aware that Your child has provided Us with Personal Data, please
        contact Us. If We become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to remove that information
        from Our servers. If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may require Your parent`s
        consent before We collect and use that information.
      </Description>
      <Subheader text="Links to Other Websites" />
      <Description>
        Our Service may contain links to other websites that are not operated by Us. If You click on
        a third party link, You will be directed to that third party`s site. We strongly advise You
        to review the Privacy Policy of every site You visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any third party sites or
        services.
      </Description>
      <Subheader text="Changes to this Privacy Policy" />
      <Description>
        {`We may update Our Privacy Policy from time to time. We will notify You of any changes by
      posting the new Privacy Policy on this page. We will let You know via email and/or a prominent
      notice on Our Service, prior to the change becoming effective and update the "Last updated"
      date at the top of this Privacy Policy. You are advised to review this Privacy Policy
      periodically for any changes. Changes to this Privacy Policy are effective when they are
      posted on this page.`}
      </Description>
      <Title text="Contact Us" />
      <Description>
        If you have any questions about this Privacy Policy, You can contact us: <br />
        <span className="font-bold">info@enigma-ai.com</span>
      </Description>
    </div>
  </div>
);
