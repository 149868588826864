import { IFeature } from "../types";

export const FEATURES: IFeature[] = [
  {
    title: "One to many",
    subTitle:
      "We  offer our customers an innovative and user-friendly platform for omnichannel communications.",
    image: "/images/live-chat-screenshot.png",
    icon: "chats-icon.svg",
    description: `Our platform offers a unique "one to many" feature through our customized chatbot builder,
      enabling you to communicate with customers across multiple channels in a single interface.
      The intuitive and customizable chatbot allows you to tailor conversations to fit your customers' specific needs.
      You can quickly respond to inquiries, provide support, and initiate sales conversations all in one place. Our secure and reliable chatbot is powered by advanced AI technology,
      ensuring your conversations are private and protected. Plus, our highly scalable chatbot allows you to add new features and capabilities as your business grows.`
  },
  {
    title: "Drag & Drop",
    subTitle:
      "We provide a simple drag-and-drop interface that allows you to customize your chatbot’s look and feel, as well as its functionality. You can also integrate your chatbot with other systems.",
    image: "/images/bot-flow-screenshot.png",
    icon: "flow-diagram.svg",
    description: `You don't need any coding knowledge to build a chatbot that can interact with your customers, answer their questions, and provide them with the information they need. With our code-free chatbot feature, you can easily drag and drop different elements to create your own unique chatbot. This saves you time and effort, allowing you to focus on providing excellent customer service and personalized experiences. Our intuitive chatbot builder makes it easy to customize your chatbot to fit your business needs, giving you complete control over the conversation flow.
`
  },
  {
    title: "All in one",
    subTitle:
      "Chatbots are quickly becoming the go-to solution for businesses looking to streamline their operations and increase efficiency. With a customized chatbot, you can automate many of your business operations, freeing up time and resources to focus on other areas of your business.",
    image: "/images/flow-node-example.png",
    icon: "dollar-arrow-up.svg",
    description: `We specialize in creating customized chatbots that can automate customer service, sales, marketing, and more. Our customized chatbots can answer customer inquiries, provide product information, process orders, and automate internal processes, saving time and money by freeing up staff to focus on more important tasks. With our tailored chatbots, we can help businesses improve customer service by providing quick and accurate responses to customer inquiries. Our team of experts will work with you to create a chatbot that is specifically tailored to your business operations, helping to automate many of your processes and save valuable time and resources.
 `
  }
];
